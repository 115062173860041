









import { Component, Vue } from 'vue-property-decorator'
import SplitPane from 'vue-splitpane'
import moduleHeader from '@/components/moduleHeader/index.vue'
import { CommonMudule } from '@/store/modules/common'
import LazyLoadImg from '@/components/LazyLoadImg/index.vue'

@Component({
  name: 'instituteConfig',
  components: {
    SplitPane,
    moduleHeader,
    LazyLoadImg,
  },
})
export default class extends Vue {
  get storyLineOrg() {
    return CommonMudule.imgSource.storyLineOrg
  }
  get list() {
    const res = CommonMudule.imgSource.assetAllocationSuggest || []
    return res
  }
}










import { Component, Vue, Prop, Watch } from 'vue-property-decorator'

type ImgListType = Array<string> | string;
interface TaskElement extends Element{
  removeTask: Function
}
@Component({
  name: 'LazyLoadImg',
})
export default class extends Vue {
  @Prop({ required: true, default: () => '' }) imgList!: ImgListType
  tasks: Element[] = []

  imgUrlList: ImgListType = [];

  @Watch('imgList', { deep: true, immediate: true })
  watchPropsImgs(v1: ImgListType) {
    if (v1) {
      if (typeof v1 === 'string') {
        this.imgUrlList = v1.split(',')
        return
      }
      this.imgUrlList = v1
    }
  }

  lazyLoadImg(imgBox: Element) {
    const img = imgBox.querySelector('img')
    const trueImg = (img as HTMLImageElement).getAttribute('data-img') || ''
    let tempImg: HTMLImageElement | null = new Image()
    if (tempImg) {
      tempImg.src = trueImg
      tempImg.onload = function() {
        if (img) {
          img.src = trueImg
          tempImg = null
        }
      }
    }
  }

  watchLoadImg() {
    const imgBoxList: NodeListOf<Element> = document.querySelectorAll('.img-box')
    const ob = new IntersectionObserver(
      (changes) => {
        changes.forEach((change) => {
          const { isIntersecting, target } = change
          if (isIntersecting) {
            this.lazyLoadImg(target);
            (target as TaskElement).removeTask(target)
            ob.unobserve(target)
          }
        })
      },
      {
        threshold: [0],
      }
    )

    imgBoxList.forEach((item: Element) => {
      (item as TaskElement).removeTask = (target: TaskElement) => this.tasks.filter(task => task.getAttribute('key') !== target.getAttribute('key'))
      ob.observe(item)
      this.tasks.push(item)
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      window.requestIdleCallback(this.loadTaskImg)
    })
  }

  loadTaskImg(deadline: {timeRemaining: Function}) {
    while (this.tasks.length > 0 && deadline.timeRemaining() > 0) {
      const task = this.tasks.shift()
      this.lazyLoadImg(task as Element)
    }
    if (this.tasks.length > 0) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      window.requestIdleCallback(this.loadTaskImg)
    }
    if (this.tasks.length === 0) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      window.cancelIdleCallback(this.loadTaskImg as any)
    }
  }

  mounted() {
    this.watchLoadImg()
  }
}
